.training-search-card-info {
    flex: 10;
    display: flex;
    flex-direction: column;
    text-align: justify;
}

@media only screen and (max-width: 1200px) {
    .training-search-card-info {
        flex: 8;
        display: flex;
        flex-direction: column;
        text-align: justify;
    }
}

.training-search-card-metadata {
    display: flex;
}

.metadata-field {
    flex: 1;
}