.configuration-container {
    min-height: 400px;
}

.training-title-track {
    /* background-color: oldlace; */
    padding-left: 24px;
}

.training-title-label {
    display: inline;
    font-size: x-large;
}

.training-title-separator {
    margin: 12px 0px;
}