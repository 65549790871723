.input-container {
    display: flex;
}

.input-filter {
    order: 0;
    flex-grow: 6;
    width: auto;
    max-width: 728px;
}

.select-filter {
    max-width: 130px;
    flex-grow: 2;
    width: auto;
}

.primary-highlight {
    color: #0972d3;
    display: flex;
    border-right: solid 1px #474e5b;
}

.secondary-highlight {
    color: #ff9900;
    display: flex;
}

.highlight-container {
    display: flex;
}

.highlight-info {
    padding: 0px 60px;
}