.audience-form-field {
    max-width: 400px;
}

.audience-form-wrapper {
    padding: 12px 0px;
}

.image-form-wrapper li span {
    width: auto !important;
    height: auto !important;
    min-height: 45px;
    display: flex;
    align-items: center;
}

.image-div {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
}