.action-label {
    min-width: 100px;
    display: inline-block;
    padding: 0px 12px;
}

.action-separator {
    border-top: none;
    border-bottom: solid 1px #eee;
}

.summary-label {
    padding: 12px 0px;
}

.language-label {
    padding: 6px 0px;
}

.training-detail-label {
    min-width: 170px;
    display: inline-block;
}

.training-detail-container {
    display: flex;
}

.spinner-container {
    display: flex;
    align-items: center;
}

.summary-fail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}