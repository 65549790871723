.primary-highlight {
    color: #0972d3;
    display: flex;
    border-right: solid 1px #474e5b;
}

.secondary-highlight {
    color: #ff9900;
    display: flex;
}

.highlight-container {
    display: flex;
}

.highlight-info {
    padding: 0px 60px;
    /* cursor: pointer; */
}