.top-nav {
    background-color: red;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1002;
    display: flex;
}

.top-nav>* {
    flex: 1;
}