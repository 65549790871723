.schedule-form-wrapper {
    display: flex;
}

.datepicker-overlay {
    position: absolute;
    width: 185px;
    height: 30px;
    border: solid 2px #7d8998;
    z-index: 99999;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 6px;
}