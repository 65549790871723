.training-filter-wrapper * {
    max-width: unset !important;
}

.loading-bar {
    display: flex;
    justify-content: center;
}

.training-search-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.training-search-card-button {
    flex: 2;
    justify-content: center;
    display: flex;
}

@media only screen and (max-width: 1200px) {

    .training-search-card-button {
        flex: 4;
        justify-content: center;
        display: flex;
    }
}

.lms-selected-training {
    padding: 12px 0px;
}