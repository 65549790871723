.audience-card-container {
    border: solid 1px #eee;
    border-radius: 10px;
}

.audience-card-label {
    background-color: #f7f7f7;
    min-height: 50px;
    padding: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.audience-card-content {
    padding: 12px;
}

.audience-badge-area {
    display: flex;
}

.audience-badge-label {
    padding-right: 12px;
    min-width: 100px;
}

.badge-spacer {
    margin: 0px 6px;
}

.audience-action-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}