.navtile {
    /* background-color: rgb(194, 190, 190); */
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
}

.navtile-disabled {
    background-color: rgb(217, 217, 217);
    pointer-events: none;
    color: grey;
}

.navtile-logo {
    flex: 1;
    width: 120px;
}

.navtile-label {
    text-decoration: none;
}